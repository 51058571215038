.side-menuAdmin {
    position: fixed;
    background: #B5B2B2;
    width: 300px;
    height: 100%;
    box-sizing: border-box;
    /*padding: 30px 20px;*/
    transition: width 0.2s ease-in;
}

.side-menuAdmin.inactive {
    width: 80px;
}

.side-menuAdmin .top-section {
    position: relative;
}

.side-menuAdmin .top-section .logo {
    width: 100%;
    background: white;
    overflow: hidden;
    display: inline-block;
    border-radius: 0px 0px 68px 0px;
}
.side-menuAdmin .top-section .logo img {
    max-width: 100%;
    max-height: 100%;
}

.side-menuAdmin .top-section .toggle-menu-btn {
    color: black;
    font-size: 30px;
    position: absolute;
    top: 32%;
    left: 86%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
}

.side-menuAdmin.inactive .top-section .toggle-menu-btn {
    right: -50px;
    position: absolute;
    top: 123%;
    left: 37%;
}
.side-menuAdmin.inactive .top-section .logo img {
    max-width: 78%;
    max-height: 100%;
}

.side-menuAdmin .search-controller {
    color: #fff;
    position: relative;
}

.side-menuAdmin .search-controller .search-btn {
    width: 40px;
    height: 35px;
    position: absolute;
    background: transparent;
    border: 0;
    font-size: 20px;
    color: #666;
}

.side-menuAdmin .search-controller input[type="text"] {
    border: 0;
    outline: none;
    height: 35px;
    background: #333;
    border-radius: 5px;
    display: block;
    margin: 20px 0;
    width: 100%;
    box-sizing: border-box;
    padding-left: 40px;
    color: #666;
}

.side-menuAdmin .main-menu .menu-item {
    color: #fff;
    text-decoration: none;
    font-size: 15px;
    display: block;
    font-weight: 600;
    cursor: pointer;
}

.side-menuAdmin .main-menu .menu-item.active {
    background: #ffff;
    color: #292929;
    border-radius: 38px 0px 0px 38px;
}
.side-menuAdmin .main-menu .menu-item.active img {
    filter: invert(80%) sepia(53%) saturate(1366%) hue-rotate(194deg) brightness(90%) contrast(110%);
}

.side-menuAdmin .main-menu {
    margin: 10px -4px 10px 88px;
    position: relative;
    max-height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.side-menuAdmin .main-menu.inactive {
    margin: 10px -4px 0px 19px;
    position: relative;
    max-height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.side-menuAdmin .main-menu .menu-item .menu-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    font-size: 20px;
    /*line-height: 40px;*/
    text-align: center;
}

.side-menuAdmin .main-menu .menu-item span {
    position: absolute;
    display: inline-block;
    line-height: 40px;
    opacity: 1;
    transition: opacity 0.2s ease-in;
}

.side-menuAdmin.inactive .main-menu .menu-item span {
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
}

.side-menuAdmin .main-menu .sub-menu {
    color: #333;
    margin-left: 20px;
    border-left: 1px solid #666;
    box-sizing: border-box;
    padding-left: 30px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in;
}

.side-menuAdmin .main-menu .sub-menu.active {
    max-height: 200px;
}

.side-menuAdmin .main-menu .sub-menu a {
    display: block;
    margin: 5px 0;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: #333;
    box-sizing: border-box;
    padding: 5px;
}

.side-menuAdmin .main-menu .sub-menu a.active {
    background: #222;
    display: block;

    border-radius: 5px;
    color: #fff;
}

.side-menuAdmin-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    box-sizing: border-box;
}

.side-menuAdmin-footer .avatar {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    display: inline-block;
}
.side-menuAdmin-footer .avatar img {
    max-width: 100%;
}

.side-menuAdmin-footer .user-info {
    display: inline-block;
    margin: 37px 12px;
    color: #fff;
    text-align: justify;
    /*position: absolute;*/
    opacity: 1;
    transition: opacity 0.2s ease-in;
}

.side-menuAdmin.inactive .side-menu-footer .user-info {
    opacity: 1;
    width: 0;
    height: 0;
    overflow: hidden;
}

.side-menuAdmin-footer .user-info h5 {
    font-size: 15px;
}

.side-menuAdmin-footer .user-info p {
    font-size: 14px;
}
