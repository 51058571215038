.switch-off.btn {
    padding-left: 1rem;
}

.restPassword{
    border: none;
    color: red !important;
    background: transparent;
}


.restPassword:hover{
    border: none;
    color: red !important;
    background: transparent;
    cursor: pointer;
}

.restPassword:focus{
    border: none;
    box-shadow: none;
    color: red !important;
    background: transparent;
    cursor: pointer;
}

.restPassword:selected{
    border: none;
    box-shadow: none;
    color: red !important;
    background: transparent;
    cursor: pointer;
}

.footerLandingImg{
    width: 35%;
}

@media (max-width: 400px) {

    .footerLandingImg{
      width: 100%;
  }
  }