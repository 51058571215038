.aside {
    position: fixed;
    top: 50px;
    bottom: 50px;
    left: 50px;
    z-index: 100;
    overflow: hidden;
    width: 300px;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    background-color: rgba(242, 243, 245);
    padding: 0;
}
.copyText {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    display: block;
    margin: 34px 0px;
    color: #5e6278;
    text-align: center;
    opacity: 1;
    position: absolute;
    bottom: 0;
    transition: opacity 0.2s ease-in;
}
.aside-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.aside-menu {
    display: flex;
    align-items: center;
    width: 100%;
    /* margin-top: -65px; */
    /* overflow: auto; */
    /* position: absolute; */
    /* height: 100%; */
}
.menu-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;
    flex: 0 0 100%;
    color: #5e6278;
    font-weight: 600;
    padding: 0.65rem 1rem;
    transition: none;
    outline: none !important;
    text-decoration: none;
}
.menu-item:hover {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;
    flex: 0 0 100%;
    color: #181c32;
    font-weight: 600;
    padding: 0.65rem 1rem;
    transition: none;
    outline: none !important;
    text-decoration: none;
}

.menu-icon-new {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    /*justify-content: center;*/
    width: 2rem;
    filter: invert(8%) sepia(9%) saturate(7500%) hue-rotate(205deg) brightness(30%) contrast(91%);
    margin-right: 0.5rem;
}

.menu-content {
    padding: 0.65rem 1rem;
}

.separator {
    display: block;
    height: 0;
    border-bottom: 1px solid #2b2b40;
}

.side-menuAdmin-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    box-sizing: border-box;
}

.side-menuAdmin-footer .avatar {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    display: inline-block;
}
.side-menuAdmin-footer .avatar img {
    max-width: 100%;
}

.side-menuAdmin-footer .user-info {
    display: inline-block;
    margin: 37px 12px;
    color: #fff;
    text-align: justify;
    /*position: absolute;*/
    opacity: 1;
    transition: opacity 0.2s ease-in;
}

.side-menuAdmin.inactive .side-menu-footer .user-info {
    opacity: 1;
    width: 0;
    height: 0;
    overflow: hidden;
}

.side-menuAdmin-footer .user-info h5 {
    font-size: 15px;
}

.side-menuAdmin-footer .user-info p {
    font-size: 14px;
}

@media screen and (max-device-width: 1200px), screen and (max-width: 768px) {
    .aside {
        position: fixed;
        top: 20px;
        bottom: 50px;
        font-size: 12px;
        left: 50px;
        z-index: 100;
        overflow: hidden;
        width: 200px;
        border-radius: 2rem;
        display: flex;
        flex-direction: column;
        background-color: rgba(242, 243, 245);
        padding: 0;
    }
    .containerTwo.inactive {
        margin-left: -240px;
    }
    .containerTwo {
        transition: margin-left 0.2s ease-in;
        padding-top: 20px;
        margin-left: -130px;
    }
    .select-css {
        border: 1px solid #b5b2b2;
        box-sizing: border-box;
        border-radius: 0.65rem;
        font-size: 12px;
    }
    .ant-pagination-item-active a {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        color: white !important;
        background: #2b2b40 !important;
        border-radius: 75px !important;
        font-family: "Poppins" !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 20px !important;
    }
    .ant-pagination-prev::after {
        content: "Atrás" !important;
        padding-left: 20px !important;
        color: #5e6278 !important;
        font-weight: 600 !important;
        cursor: pointer;
        font-size: 14px;
    }
    .ant-pagination-next::before {
        content: "Siguiente" !important;
        padding-right: 20px !important;
        color: #5e6278 !important;
        font-weight: 600 !important;
        cursor: pointer;
        font-size: 14px;
    }
    .share-icon2 {
        width: 18.66px;
        margin-top: 4px;
    }
    .menu-icon-css {
        width: 20px;
        height: 40px;
    }
}

@media (max-width: 830px) {
    .aside {
        display: none !important;
    }
    .containerTwo {
        transition: margin-left 0.2s ease-in;
        padding-top: 0px;
        padding-left: 0px;
        margin-left: 0px;
    }
    .containerTable {
        padding-right: 0px;
    }
}
@media (max-width: 400px) {
    .aside {
        display: none !important;
    }
    .containerTwo {
        transition: margin-left 0.2s ease-in;
        padding-top: 0px;
        padding-left: 0px;
        margin-left: 0px;
    }
    .containerTable {
        padding-right: 0px;
    }
}
