* {
  margin: 0;
  padding: 0;
}

li > .active {
  font-weight: bold;
  /*background-color: red;*/
  color: #181c32;
}
.centerDiv {
  height: 100vh;
  width: 100%;
  display: flex;
}
.blackText {
  color: black;
}
.font17 {
  font-size: 16px !important;
}
.buttonLanding {
  background: #F1595C;
  border: #00b8a5;
  border-radius: 5px;
  width: 200px;
}
.containerBoxInfo {
  padding: 20px;
  width: 360px;
}
.buttonLanding:hover {
  background: #F1595C;
  border: #00b8a5;
  border-radius: 5px;
  width: 200px;
}
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
body {
  /*background-color: #f2f2f2 !important;*/
  background-color: #f9fafb;
  font-family: Poppins, Helvetica, "sans-serif";
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 10px;
  display: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333;
  display: block;
}

.side-menu {
  position: fixed;
  background: #ff0b0b;
  width: 300px;
  height: 100%;
  box-sizing: border-box;
  /*padding: 30px 20px;*/
  transition: width 0.2s ease-in;
}

.side-menu.inactive {
  width: 80px;
}

.side-menu .top-section {
  position: relative;
}
.logo-css {
  width: 150px;
  margin: auto;
  margin-bottom: 38px;
  /*display: flex;*/
}
.logo-css-certi {
  width: 150px;
  margin: auto;
  margin-bottom: 0px;
  display: flex;
}
.menu-icon-css {
  width: 24px;
  height: 46px;
}
.side-menu .top-section .logo {
  width: 100%;
  background: white;
  overflow: hidden;
  display: inline-block;
  border-radius: 0px 0px 68px 0px;
}
.side-menu .top-section .logo img {
  max-width: 100%;
  max-height: 100%;
}

.side-menu .top-section .toggle-menu-btn {
  color: black;
  font-size: 30px;
  position: absolute;
  top: 32%;
  left: 86%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

.toggle-menu-btn {
  display: flex;
  justify-content: end;
  padding-right: 20px;
  cursor: pointer;
}

.side-menu.inactive .top-section .toggle-menu-btn {
  right: -50px;
  position: absolute;
  top: 123%;
  left: 37%;
}
.side-menu.inactive .top-section .logo img {
  max-width: 78%;
  max-height: 100%;
}

.side-menu .search-controller {
  color: #fff;
  position: relative;
}

.side-menu .search-controller .search-btn {
  width: 40px;
  height: 35px;
  position: absolute;
  background: transparent;
  border: 0;
  font-size: 20px;
  color: #666;
}

.side-menu .search-controller input[type="text"] {
  border: 0;
  outline: none;
  height: 35px;
  background: #333;
  border-radius: 5px;
  display: block;
  margin: 20px 0;
  width: 100%;
  box-sizing: border-box;
  padding-left: 40px;
  color: #666;
}

.side-menu .main-menu .menu-item {
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  display: block;
  font-weight: 600;
  cursor: pointer;
}
.padding-li {
  padding-top: 20px;
}
.side-menu .main-menu .menu-item.active {
  background: #ffff;
  color: #292929;
  border-radius: 38px 0px 0px 38px;
}
.share-icon2 {
  width: 20.66px;
  margin-top: 4px;
}
.side-menu .main-menu .menu-item.active img {
  filter: invert(75%) sepia(63%) saturate(7061%) hue-rotate(356deg) brightness(98%) contrast(117%);
}

.side-menu .main-menu {
  margin: 10px -4px 10px 88px;
  position: relative;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.side-menu .main-menu.inactive {
  margin: 10px -4px 0px 19px;
  position: relative;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.side-menu .main-menu .menu-item .menu-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 20px;
  /*line-height: 40px;*/
  text-align: center;
}

.side-menu .main-menu .menu-item span {
  position: absolute;
  display: inline-block;
  line-height: 40px;
  opacity: 1;
  transition: opacity 0.2s ease-in;
}
.aside-logoNone {
  display: none;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}
.aside-logoNone.active {
  display: flex;
}
.aside-logo.inactive {
  display: none;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}
.menu-content.inactive {
  display: none;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}
.side-menu .inactive .menu-item span {
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

.side-menu .main-menu .sub-menu {
  color: #333;
  margin-left: 20px;
  border-left: 1px solid #666;
  box-sizing: border-box;
  padding-left: 30px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in;
}

.side-menu .main-menu .sub-menu.active {
  max-height: 200px;
}

.side-menu .main-menu .sub-menu a {
  display: block;
  margin: 5px 0;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  color: #333;
  box-sizing: border-box;
  padding: 5px;
}

.side-menu .main-menu .sub-menu a.active {
  background: #222;
  display: block;

  border-radius: 5px;
  color: #fff;
}

.side-menu-footer {
  width: 100%;
  /* position: absolute; */
  /* top: 100%; */
  bottom: 0px;
  left: 0;
  /* padding: 20px; */
  box-sizing: border-box;
}

.side-menu-fav {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  /*padding: 20px;*/
  box-sizing: border-box;
}
.side-menu-fav.inactive {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  /*padding: 20px;*/
  box-sizing: border-box;
}
.side-menu-fav.inactive .user-fav {
  margin: 34px 0px;
  color: #5e6278;
  text-align: justify;
  /*position: absolute;*/
  opacity: 1;
  transition: opacity 0.2s ease-in;
}
.user-fav {
  display: none;
  margin: 34px 0px;
  color: #5e6278;
  text-align: justify;
  /*position: absolute;*/
  opacity: 1;
  transition: opacity 0.2s ease-in;
}
.whiteText {
  color: white;
}
.font20 {
  font-size: 20px !important;
}
.fav-css {
  width: 30%;
  height: 100%;
  margin: auto;
  margin-bottom: 100px;
  display: flex;
}
.mbottom {
  margin-bottom: 0px !important;
}

.side-menu-footer .avatar {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
}
.side-menu-footer .avatar img {
  max-width: 100%;
  left: 0;
  right: 0;
}

.side-menu-footer .user-info {
  left: 0;
  right: 0;
  display: block;
  margin: 34px 0px;
  color: #5e6278;
  text-align: center;
  opacity: 1;
  position: absolute;
  bottom: 0;
  transition: opacity 0.2s ease-in;
}

.side-menu.inactive .side-menu-footer .user-info {
  opacity: 1;
  width: 0;
  height: 0;
  overflow: hidden;
}
.side-menu.inactive .side-menu-fav .user-fav {
  /*opacity: 1;
  width: 0;*/
  height: 100%;
}

.side-menu-footer .user-info h5 {
  font-size: 15px;
}

.side-menu-footer .user-info p {
  font-size: 14px;
}
.borderRow {
  border-bottom: 1px solid #000b3d;
  padding-bottom: 5px !important;
}
.divider {
  width: 100%;
  height: 1px;
  border-radius: 1px;
  background: #333;
}
.bgPrimary {
  background: #000b3d;
}
.bgSecundary {
  background: #eabe3f;
}
.containerEsade {
  margin-left: 288px;
  transition: margin-left 0.2s ease-in;
  /*padding-left: 50px;*/
  padding-right: 20px;
  box-sizing: border-box;
  max-width: -webkit-fill-available;
}

.containerTwo {
  transition: margin-left 0.2s ease-in;
  padding-top: 50px;
  padding-left: 374px;
}

.containerTwo2 {
  transition: margin-left 0.2s ease-in;
  padding-top: 50px;
  padding-left: 174px !important;
}

.containerTwo.inactive {
  margin-left: -208px;
}

.hii {
  padding: 0px !important;
}

@media (max-width: 400px) {
  .containerTwo {
    transition: margin-left 0.2s ease-in;
    padding-top: 0px;
    padding-left: 0px;
    margin-left: 0px !important;
  }
  .containerTable {
    padding-right: 0px;
  }

}

@media screen and (max-device-width: 1200px), screen and (max-width: 768px) {
  p {
    font-size: 12px !important;
  }
}


