
body {
    /*background-color: #f2f2f2 !important;*/
    background-color: #f9fafb;
    font-family:  Poppins, Helvetica, "sans-serif";
}


#filtro-fecha-docs{

    text-align: center;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

/*tooltip*/

/* Tooltip container */
.text-start {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .tooltip{
    position: relative;
    display: contents;
    cursor: pointer;
  }

  .tooltip-container{

  }
  
  /* Tooltip text */
  .tooltip-container .tooltiptext {
    visibility: hidden;
    width: 320px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
   
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip-container:hover .tooltiptext {
    visibility: visible;
  }

.card-title {
    color: white;
    font-family: Poppins, sans-serif;
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    text-align: left;
    text-align: initial;
    margin-bottom: 0px !important;
}

.ant-input {
    background: transparent;
    border-radius: 82px;
    padding: 10px;
    padding-left: 20px;
    /*margin-left: 50px;*/
    margin-right: 4px;
    border: 1px solid #b5b2b2;
    /*width: 300px;*/
}
.docColor {
    filter: invert(11%) sepia(100%) saturate(7295%) hue-rotate(360deg) brightness(104%) contrast(117%);
}
.cancelButton {
    border: 1px solid #f2f3f5;
    color: #181c32;
}
.cancelButton:hover {
    background-color: #2b2b40;
}
.confirmButton {
    /*background-color: #2B2B40;*/
    border: 1px solid #2b2b40 !important;
    color: #181c32;
}
.confirmButton:hover {
    background-color: #2b2b40;
}
.alertBurn {
    
    z-index: 1000;
    width: 745px;
    color: red;
    background-color: rgba(242, 243, 245);
    border-color: red;
}
.editInput {
    border: 1px solid #b5b2b2 !important;
    padding-left: 10px !important;
}
.colorEdit {
    color: #a1a5b7;
}
.noBorder {
    border: none !important;
    background-color: transparent !important;
}
.boxCardDocuments {
    background-color: #F9B937;
}
.boxCardVisual {
    background-color: #00AE88;
}
.boxCardAlmace {
    background-color: #224BA0;
}
.boxCardPlan {
    background-color: #F1595C;
}
.boxCardDescuento{
    background-color: #000B3D;
}
.restarButtom {
    color: white !important;
    background-color: #2b2b40;
    border: 1px solid #b5b2b2;
    border-radius: 24px;
    padding: 20px;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    height: 52px;
}
.spinner-borderData {
    display: inline-block;
    margin-left: 20px;
    width: 3rem;
    height: 3rem;
    vertical-align: -0.125em;
    border: 0.25em solid currentColor;
    border-color: red;
    border-right-color: transparent;
    border-radius: 50%;
    animation: 0.75s linear infinite spinner-border;
}
.containerTable {
    padding-right: 20px;
}
.ant-table-row {
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: #e4e6ef;
}
.editIcon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}
.btn-Edit {
    background-color: transparent;
    border-color: none;
    border: none;
}
.btn-Edit:hover {
    cursor: pointer;
    background-color: transparent;
}
.table-responsive {
    overflow-x: hidden !important;
    overflow: hidden !important;
    /*padding-right: 40px;*/
    padding-top: 30px;
    box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%);
    border-radius: 20px;
    padding-bottom: 50px;
    background-color: white;
}
.ant-input-search-button {
    display: none;
}
.ms-n5555 {
    margin-top: 3px;
    margin-left: -47px;
}
.textField {
    height: 32px;
    width: 200px;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    background: #e3e3e3;
    border-radius: 82px;
    position: relative;
    margin-left: 50px;
}
.filterSearch {
    margin-left: 50px;
    background: #e3e3e3;
    width: 400px !important;
}
.container-user {
    display: flex;
}
.user-info-nav {
    text-align: center;
    align-self: center;
    padding-right: 20px;
}
.loge-out-css {
    color: black;
    background: transparent;
    border-color: transparent;
}
.loge-out-css:hover {
    color: black;
    background: transparent;
    border-color: transparent;
    text-decoration: underline;
}
.colorSVG {
    filter: invert(70%) sepia(4%) saturate(1380%) hue-rotate(195deg) brightness(93%) contrast(92%);
}
.name-user {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0;
}
.rol-user {
    font-weight: 600;
    font-size: 12px;
    color: #a1a5b7;
    margin-bottom: 0;
    font-weight: 600 !important;
}
.user-css {
    width: 57px;
    height: 57px;
    border-radius: 65px;
}
.ant-table-thead {
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: #e4e6ef;
}
.textField:hover {
    cursor: pointer;
}
.ms-n5 {
    margin-left: -40px;
}

.select-css {
    border: 1px solid #b5b2b2;
    box-sizing: border-box;
    border-radius: 0.65rem;
}
.select-css-clientes {
    border: 1px solid #b5b2b2;
    box-sizing: border-box;
    /* border-radius: 46px; */
}
.btnBuscar {
    height: 34px;
    width: 32px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.barraBusqueda {
    float: left;
    /*padding: 20px;*/
}
.flexCol {
    text-align: center;
    display: flex;
    justify-content: center;
}
.SelectBusqueda {
    padding-bottom: 20px;
}

.title-filter {
    margin-bottom: 10px;
    margin-left: 15px;
    font-size: 9pt;
    font-weight: 800;
}

.margin-top {
    margin-top: 40px;
}

.uploadButtonModal {
    margin-top: 28px;
    color: white !important;
    background-color: #2b2b40;
    border: 1px solid #b5b2b2;
    box-sizing: border-box;
    border-radius: 0.65rem;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    height: 52px;
    /* text-align: center; */
}

.uploadButton {
    margin-top: 38px;
    color: white !important;
    background-color: #2b2b40;
    border: 1px solid #b5b2b2;
    box-sizing: border-box;
    border-radius: 0.65rem;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    height: 52px;
}

.batchDeleteButton {
    margin-top: 38px;
    color: white !important;
    border: 1px solid #b5b2b2;
    box-sizing: border-box;
    border-radius: 0.65rem;
    display: flex;
    background: red !important;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    height: 52px;
}

.batchDeleteButton:hover{
    background-color: red;
}
.uploadUser {
    margin-top: 38px;
    color: white !important;
    background-color: #2b2b40;
    border: 1px solid #b5b2b2;
    box-sizing: border-box;
    border-radius: 0.65rem;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    height: 52px;
    margin-left: 20px;
}
.uploadUser:hover,
.uploadUser:focus {
    box-shadow: none;
    margin-top: 38px;
    color: white !important;
    background-color: #2b2b40;
    border: 1px solid #b5b2b2;
    box-sizing: border-box;
    border-radius: 0.65rem;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    height: 52px;
    margin-left: 20px;
}
.uploadButton:hover,
.uploadButton:focus {
    box-shadow: none;
    color: white !important;
    background-color: #2b2b40;
    border: 1px solid #b5b2b2;
    box-sizing: border-box;
    border-radius: 0.65rem;
}
.antd-table-custom-class thead th,
.antd-table-custom-class tbody td {
    white-space: nowrap;
    word-break: break-word;
    word-break: break-all;
}
.antd-table-custom-class thead th,
.antd-table-custom-class tbody td {
    white-space: pre-wrap;
    text-overflow: ellipsis;
}
.flex {
    display: flex;
    justify-content: center;
    vertical-align: middle;
}
/* table */
.ant-table {
    width: 100% !important;
    /*display: flex;
    justify-content: end;*/
    margin-left: 10px;
    overflow-x: hidden;
    overflow: hidden !important;
}
.etidisplay {
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px !important;
}
.imgDocu {
    filter: invert(100%) sepia(82%) saturate(0%) hue-rotate(265deg) brightness(106%) contrast(101%);
    width: 65.59px;
    margin-left: 70px;
}
.ant-tag {
    background: transparent;
    border: 0px;
}
.fontWc {
    font-weight: 700 !important;
    color: #181C32 !important

}
/* row data */
.ant-table-tbody > tr > td {
    padding-right: 20px;
    padding-left: 20px;
    height: 5px !important;
    padding-bottom: 5px !important;
    padding-top: 5px !important;
    color: #181c32;
    text-align: center;
}
.ant-table-thead > tr > th {
    height: 5px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    text-align: center;
    width: auto;
    font-weight: 700;
    white-space: break-spaces;
}
.ant-pagination {
    display: flex !important;
    margin-top: 20px !important;
    justify-content: end;
    border-radius: 61px !important;
    width: auto !important;
    height: auto !important;
    padding-bottom: 8px !important;
    padding-top: 8px !important;
}

.ant-pagination-prev::after {
    content: "Atrás" !important;
    padding-left: 20px !important;
    color: #5e6278 !important;
    font-weight: 600 !important;
    cursor: pointer;
}
.ant-pagination-next::before {
    content: "Siguiente" !important;
    padding-right: 20px !important;
    color: #5e6278 !important;
    font-weight: 600 !important;
    cursor: pointer;
}
.ant-pagination-item-link {
    border: 0px !important;
    background: transparent !important;
}

.ant-pagination-prev {
    margin-right: 10px;
}

.ant-pagination-next {
    margin-left: 10px;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
     display: contents; 
    width: 100%; 
}

.ant-pagination-item {
    border: 0px !important;
    padding: 10px !important;
    color: #b5b2b2 !important;
    border-radius: 61px !important;
    /*background: rgba(16, 41, 115, 0.15) !important;*/
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-radius: 61px !important;
    width: auto !important;
    height: auto !important;
    padding-bottom: 10px !important;
    padding-top: 8px !important;
    margin-top: -8px;
    position: relative !important;
}
.anticon-left {
    padding-left: 10px !important;
    color: #b5b2b2 !important;
}
.anticon-right {
    padding-right: 10px !important;
    color: #b5b2b2 !important;
}
.ant-pagination-item-active a {
    /*width: 68px !important;*/
    /*background: rgba(16, 41, 115, 0.15) !important;*/
    /*height: 61.18px !important;*/
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    color: white !important;
    background: #2b2b40 !important;
    border-radius: 75px !important;
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 27px !important;
}
.closed-css {
    width: 28.86px;
    height: 28.67px;
}

.out-css:hover {
    background: #f4f4f4;
    border-radius: 54px;
    color: #9f9f9f;
    width: 144.42px;
    height: 57px;
    font-size: 18px;
    border: transparent;
    font-weight: 600;
}

.playerProfilePic_home_tile {
    width: 80px;
}

.uploadLogotipo {
    margin: 0 auto;
    color: white !important;
    background-color: #2b2b40;
    border: 1px solid #b5b2b2;
    border-radius: 24px;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    height: 41px;
}

.uploadLogotipo:hover {
    margin: 0 auto;
    color: white !important;
    background-color: #2b2b40;
    border: 1px solid #b5b2b2;
    border-radius: 24px;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    height: 41px;
}

.previewProfilePic {
    display: flex;
    justify-content: center;
    margin-bottom: 10%;
}

.out-modal {
    margin: 0 auto;
    color: #92929f;
    border-color: #f4f4f4 !important;
    /* background: #B5B2B2; */
    border-radius: 24px;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    background: #f4f4f4;
    border-radius: 54px;
    height: 52px;
}

.out-modal:hover {
    border: 0px;
    background-color: transparent;
    color: #92929f;
}

.Content {
    padding: 40px;
}

.form-select {
    background-image: url("../../assets/select.svg");
}

.ant-table-tbody {
    font-size: 14px;
}

.breakText {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
}

.breakTextDoc {
    color: #181c32;
    text-decoration: underline;
    font-weight: 600;
    white-space: break-spaces;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    width: 179px;
    margin-bottom: 0;
}
.breakTextDoc2 {
    color: #181c32;
    font-weight: 600;
    white-space: break-spaces;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    /*width: 179px;*/
    margin-bottom: 0;
}
.textEmit {
    color: #181C32;
    font-weight: 700;
    margin-bottom: 0px !important;
}
.flexiEmit{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ant-pagination-options {
    display: none;
}

.ant-pagination-item-ellipsis {
    display: none;
}

.ant-pagination-jump-next {
    display: none;
}
.ant-pagination-jump-next-custom-icon {
    display: none;
}

.ant-input-number-handler-wrap {
    display: none !important;
}

.breakText2 {
    white-space: break-spaces;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    margin-bottom: 0;
}
.ant-card-head-title {
    color: #181c32;
    font-size: 1rem !important;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    /* font-size: 27px; */
    line-height: 50px;
    text-align: initial;
}
.ant-card-head {
    flex: 1;
    display: grid;
    align-items: center;
}
.ant-card-body {
    flex: 1;
    display: grid;
    align-items: center;
}
.textContent {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.3rem !important;
    /* font-size: 12px; */
    /* line-height: 0; */
    text-align: initial;
    color: white;
    margin: 0;
}

.card-docu {
    box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%);
    border-radius: 10px;
    border: 0;
    display: flex;
    flex-flow: column;
    min-width: 117px;
    padding: 5px 10px;
    /*padding: 1rem 1.25rem;*/
}
.ant-table-column-title {
    position: relative;
    z-index: 1;
    flex: 1 1;
    color: #212529;
    font-weight: 700;
    font-size: 10px;
}

.card-Visualizaciones {
    box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%);
    border-radius: 10px;
    border: 0;
    display: flex;
    flex-flow: column;
    min-width: 123px;
    padding: 5px 10px;
    /*padding: 1rem 1.25rem;*/
}
.card-almacenamiento {
    box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%);
    border-radius: 10px;
    border: 0;
    display: flex;
    flex-flow: column;
    min-width: 117px;
    padding: 5px 10px;
    /*padding: 1rem 1.25rem;*/
}

.row {
    margin: 0;
    padding: 0px 0px 15px 0px;
}
.noPadding {
    padding: 0px !important
}
.col {
    margin: 10px 0px;
}

.ant-tooltip {
    display: none !important;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    z-index: 1070;
    /*display: block;*/
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    width: intrinsic;
    max-width: 250px;
    visibility: visible;
}

.ant-table-column-sorters {
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: space-between;
}
/*.ant-table-column-sorters::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }*/
.ant-table-column-sorter {
    margin-left: 0px;
    margin-right: 0px;
    color: black;
    font-size: 0;
    transition: color 0.3s;
}
.ant-table-column-sorter-inner {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}
.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
    font-size: 12px;
    cursor: pointer;
}

.flexSrc {
    display: flex;
    align-content: center;
    align-self: center;
    justify-content: center;
}
.dOndevice {
    display: inline;
}
.wBtn {
    width: 350px;
}
.positionChange {
    position: absolute;
    /*top: 83%;*/
}

.wButton {
    width: 350px;
}
.pModal {
    padding: 0px !important;
}
.editButton {
    background: transparent !important;
}

@media (max-width: 400px) {
    .containerTable {
        padding-right: 0px;
    }
    .container-user {
        display: block;
        margin-top: 10px;
    }
    .table-responsive {
        padding-top: 0px;
    }
    .ant-card-head-title {
        color: #181c32;
        font-size: 0.8rem !important;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 700;
        /* font-size: 27px; */
        line-height: 40px;
        text-align: initial;
    }
    .imgDocu {
        filter: invert(100%) sepia(82%) saturate(0%) hue-rotate(265deg) brightness(106%) contrast(101%);
        width: 25%;
        margin-left: 50px;
        margin-bottom: 10px;
    }
    .textContent {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 0.92rem !important;
        /* font-size: 12px; */
        /* line-height: 0; */
        text-align: initial;
        color: white;
        margin: 0;
    }
    .ant-pagination {
        display: flex !important;
        margin-top: 20px !important;
        justify-content: center;
        border-radius: 61px !important;
        width: auto !important;
        height: auto !important;
        padding-bottom: 8px !important;
        padding-top: 8px !important;
    }
    .flexCol {
        text-align: center;
        display: flex;
        padding: 0px;
        justify-content: center;
    }
    .dOndevice {
        display: none;
    }
    .wBtn {
        width: auto;
    }
    .marginLabels {
        margin-bottom: 20px;
    }
    .positionChange {
        position: absolute;
        top: 84%;
    }
    .info-text {
        font-family: "Poppins";
        font-style: italic;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        color: rgba(12, 12, 12, 0.7);
        margin-bottom: 0px;
        word-break: break-all;
    }

    .Content {
        padding: 0px;
    }

    .wButton {
        width: auto;
    }

    .pModal {
        padding: 0px !important;
    }
}

@media screen and (max-device-width: 1366px), screen and (max-width: 768px) {
    .select-css {
        border: 1px solid #b5b2b2;
        box-sizing: border-box;
        border-radius: 0.65rem;
        font-size: 12px;
    }
    .card-title {
        color: white;
        font-family: Poppins, sans-serif;
        font-size: 0.7rem !important;
        font-style: normal;
        font-weight: 700;
        line-height: 50px;
        text-align: left;
        text-align: initial;
        margin-bottom: 0px !important;
    }
    .ant-card-head-title {
        color: #181c32;
        font-size: 0.7rem !important;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 700;
        /* font-size: 27px; */
        line-height: 50px;
        text-align: initial;
    }
    .imgDocu {
        filter: invert(100%) sepia(82%) saturate(0%) hue-rotate(265deg) brightness(106%) contrast(101%);
        width: 65.59px;
        margin-left: 15px;
    }
    .textContent {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 0.8rem !important;
        /* font-size: 12px; */
        /* line-height: 0; */
        text-align: initial;
        color: white;
        margin: 0;
    }
    .ant-table-thead > tr > th {
        height: 5px !important;
        font-size: 10px;
        padding-left: 30px !important;
        padding-right: 30px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        text-align: center;
        width: auto;
        white-space: break-spaces;
    }
    .ant-table-tbody > tr > td {
        height: 5px !important;
        font-size: 10px;
        padding-bottom: 5px !important;
        padding-top: 5px !important;
        color: #181c32;
        text-align: center;
    }
    .docColor {
        filter: invert(11%) sepia(100%) saturate(7295%) hue-rotate(360deg) brightness(104%) contrast(117%);
        width: 20px;
    }
    .ant-pagination {
        font-size: 10px;
    }
    .loge-out-css {
        color: black;
        background: transparent;
        border-color: transparent;
        text-decoration: underline;
        font-size: 12px;
    }
    .uploadButton {
        font-size: 12px;
        box-shadow: none;
        color: white !important;
        background-color: #2b2b40;
        border: 1px solid #b5b2b2;
        box-sizing: border-box;
        border-radius: 0.65rem;
    }
    .uploadUser {
        font-size: 12px;
    }
    .imgpdf {
        width: 20px;
    }
}
