.control {
    width: 228px;
}
.bgPrimaryLogin {
    background-image: url("../../assets/logo/bgesade.jpg");
    background-size: cover;
}
.bgCapaLogin {
    height: 100vh;
    /* background-color: red; */
    background: rgba(0, 11, 61, 0.3);
}
.main-form {
    text-align: center;
}
.form-css {
    padding: 10px;
    text-align: center;
}
.input-login {
    border: 1px solid #b5b2b2;
    box-sizing: border-box;
    border-radius: 46px;
    background: transparent;
    width: 200px;
}
.login-button {
    background: #ff0b0b;
    border-radius: 24px;
    width: 200px;
    color: white;
    height: 42px;
    border-color: red;
    margin-top: 15px;
}

.content-form {
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.sendButton {
    width: 100px;
    background: red;
    color: white;
    border-radius: 50px;
}

.cancelButtonLogin {
    border: none;
    width: 100px;
}

.cancelButtonLogin:hover {
    border: none;
    width: 100px;
    cursor: pointer;
}

.passwordButton {
    background: transparent !important;
    font-size: 12px;
}
