.nav-logo{
    width: 15%;
}
.margin-navbar{
    margin-left: 40px;
}
.navTitle{
    color: #212529 !important;
    font-weight: 600;
    margin-bottom: 0;
}
.nav-css{
    position: relative;
    margin-top: 0%;
    padding-left: 12pt;
}
.dropdownMenu{
    z-index: 105;
    position: fixed;
    margin-top: 7px;
    max-height: 230px;
    overflow: auto;
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
    width: 300px;
    border-radius: 0.65rem;
    background: white;
    box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);
}

.nameSearch {
    margin-bottom: 0px;
    color: #3F4254;
    font-weight: 600 !important;
}
.searchInfotext {
    color: #A1A5B7;
    font-weight: 600 !important;
}
/*.containerInfo{
    margin-bottom: 1.25rem !important

}*/
.AlingImg{
    display: flex;
    vertical-align: middle;
    justify-content: center;
}
.nav-icon{
    width: 25.66px;
}
.nav-button{
    border:0px !important;
}
.nav-button:hover{
    background: transparent;
    border: 0px;
}

form.nosubmit {
    /*border: 1px solid #323248;*/
    border-radius: 0.65rem;
    padding: 0;
  }
/*.displayLinks{
    display: none;
}*/  
Input.nosubmit {
    border-radius: 0.65rem;
    width: 260px;
    display: block;
    padding: 9px 4px 9px 40px;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
  }

  @media (max-width: 400px) {
    .nav-css{
        position: relative;
        margin-top: 0%;
        padding-left: 2pt;
    }
    .margin-navbar {
        margin-left: 40px;
        margin-top: 20px;
    }
    /*.displayLinks{
        display: inline;
    }*/
    .infoSmall {
        display: none;
    }
    }
    @media screen and (max-device-width:1200px), screen and (max-width:768px) {
        Input.nosubmit {
            border-radius: 0.65rem;
            width: 184px !important;
            display: block;
            padding: 9px 4px 9px 40px;
            font-size : 12px;
        }
    }


  