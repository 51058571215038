.modal-header {
    border-bottom: 0px;
}
.modal-content {
    border-radius: 20px;
    padding: 10px;
}
.modal-footer {
    border: 0px;
}
.newCategory-css{
    font-weight: 600;
    background: transparent !important;
}

.showing .MuiChip-deleteIcon{
    display: none;
}
.disabledInput {
    border: 0px important
}
.WAMuiChipInput-chipContainer-4 {
    cursor: text;
    display: flex;
    flex-flow: row wrap;
    min-height: 50px !important;
    margin-bottom: -2px;
}
.ant-input-number-input{
    border: 1px solid #B5B2B2;
    box-sizing: border-box;
    border-radius: 0.65rem;
    padding: 10px;
    text-align: center;
    font-weight: 600;
    background: transparent !important;
}
.ant-input-number-input::after{
    content: '%' !important;
}

.css-1s2u09g-control {
    border-color: #B5B2B2 !important;
}

.border{
    border-radius: 8px;
}
.WAMuiChipInput-chipContainer-4 {
    cursor: text;
    display: flex;
    flex-flow: row wrap;
    min-height: 40px;
    padding: 5px;
    margin-bottom: -7px;
}
.WAMuiChipInput-underline-14:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 0px;
    pointer-events: none;
}
.ReactTags__tagInputField{
    border: 1px solid #B5B2B2 !important;
    border-radius: 8px !important;
    padding: 5px !important;
}
.input-Form {
    background: #f4f4f4;
    border-radius: 8px;
    border: 0px;
}
.select-css-modal {
    border: 1px solid #B5B2B2;
    box-sizing: border-box;
    background-color: transparent !important;
}
.select-css-modal-alert {
    border: 1px solid red;
    box-sizing: border-box;
    background-color: transparent !important;
}
.title-filter-modal {
    color: #181C32;
    font-weight: 700;
    margin-bottom: 10px;
}
.redColorModal1 {
    color: #181C32;   
     cursor: pointer;
    margin-top: 5px;
    text-decoration: none;
    font-size: 9pt;
    font-weight: 700;
    background: transparent;
    border:0px;
}
.redColorModal1:hover {
    color: #181C32;    
    cursor: pointer;
    text-decoration: none;
    font-size: 9pt;
    font-weight: 700;
    background: transparent;
    border:0px;
}
.redColorModal1:active {
    color: #181C32;
    cursor: pointer;
    text-decoration: none;
    font-size: 9pt;
    font-weight: 700;
    background: transparent;
    border:0px;
}
.redColor {
    color: #181C32;
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.01em;
    font-family: "Poppins";
    height: 18px;
    font-style: normal;
    background: transparent;
    border:0px;
}

.redColor:hover {
    color: #181C32;
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.01em;
    font-family: "Poppins";
    height: 18px;
    font-style: normal;
    background: transparent;
    border:0px;
}

.ant-tag{
    margin-right: 5px !important;
}
.modal-title {
    font-weight: 700;
    color: #181C32;
    font-size: 1.25rem;
}
.dis-row{
    justify-content: center;
    display: flex;
}
.row-select {
    margin-top: 40px;
}
.custom-button{
    background-color: #2B2B40 !important;
    border: 1px solid #B5B2B2 !important;
    box-sizing: border-box !important;
    border-radius: 0.65rem !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}
.next-css {
    background-color: #2B2B40;
    border: 1px solid #B5B2B2;
    box-sizing: border-box;
    border-radius: 0.65rem;
    font-size: 14px;
    font-weight: 600;
}
.next-css:hover {
    background-color: #2B2B40;
    border: 1px solid #B5B2B2;
    box-sizing: border-box;
    border-radius: 0.65rem;
    font-size: 14px;
    font-weight: 600;
}
.out-css {
    background: #f4f4f4;
    border-radius: 54px;
    color: #9f9f9f;
    font-size: 14px;
    border: transparent;
    font-weight: 600;
}

.out-csz {
    background: #f4f4f4;
    border-radius: 54px;
    color: #9f9f9f;
    width: auto;
    font-size: 16px;
    border: transparent;
    font-weight: 600;
}

.burn-bottom {
    background: red;
    border-radius: 54px;
    color: white;
    width: auto;
    font-size: 16px;
    border: transparent;
    font-weight: 600;
}

.burn-bottom:hover {
    background: red;
    border-radius: 54px;
    color: white;
    width: auto;
    font-size: 16px;
    border: transparent;
    font-weight: 600;
}

.out-csz:hover {
    background: #f4f4f4;
    border-radius: 54px;
    color: #9f9f9f;
    width: auto;
    font-size: 16px;
    border: transparent;
    font-weight: 600;
}


.out-css-header {
    background: #f4f4f4;
    border-radius: 50px;
    color: #9f9f9f;
    font-size: 20px;
    border: transparent;
    font-weight: 600;
}
.out-css-header:hover {
    background: #f4f4f4;
    border-radius: 50px;
    color: #9f9f9f;
    font-size: 20px;
    border: transparent;
    font-weight: 600;
}


.closed-css-modal{
    width: 18.86px;
    height: 20.67px;
}

.info-box {
    background: #fbfbfb;
    border: 1px solid #c2c2c2;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 15px;
}
.info-text-yellow{
    color: #52B141;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;
}
.info-text{
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(12, 12, 12, 0.7);
    margin-bottom: 0px;
    word-break: break-all;
}
.tag-wrapper{
    padding: 3px;
    border: 1px solid #B5B2B2 !important;
    border-radius: 5px;
    cursor:pointer;
    margin-left: 5px;
    margin-top: 48px;
}

.tag-wrapper {
    padding: 3px;
    border: 1px solid #B5B2B2 !important;
    border-radius: 5px;
    cursor: pointer;
    opacity: 1;
    cursor: move;
    margin-top: 48px;
    margin-left: 5px;
}
.ReactTags__remove{
    margin-left: 10px;
    border: 0px;
    background: transparent;
}

.ReactTags__tagInput{
    margin-top: 5px;
}

.flex{
    display: flex;
}

.info-css{
    margin: 0 auto;
}

.cloud-css{
    margin: 0 auto;
    width: 50px;
}

.share-icon{
    width: 20.66px;
    margin-right: 6px;
}

.tag-span{
    padding: 10px;
    margin-right: 10px;
    font-weight: 600;
    margin-bottom: 0px;
}
.fileName-css{
    font-weight: 700;
    color: #000000;
}

.text-validate{
    color: red;
    font-weight: 600;
}

.modal-content {

    width: 100% !important;

}

.registered-input{
    font-weight: 600;
    padding-left: 0;
    background: transparent !important;
}

/*.custom-ui-main{
    position: absolute;
    left: 50%;
    top: 40%;
}*/

.custom-ui-chill{
    text-align: center;
}

@media (max-width: 400px) {
    .modal-title {
        font-weight: 700;
        color: #181C32;
        font-size: 1.1rem;
    }
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1055;
        display: none;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        outline: 0;
        padding: 0px !important;
    }
}