.annotationLayer section {
    position: absolute;
    text-align: initial;
    display: none !important;
}
.linkAnnotation {
    display: none !important;
}

.react-pdf__Page__canvas {
    display: block;
    user-select: none;
    /*padding: 20px;*/
    width: 841px !important;
    height: 595px !important;
    box-shadow: 0px 0px 5px 1px rgb(0 11 61 / 40%);
}
.letraPrimary {
    color: #000b3d;
}

.row-btns{
    margin: 0;
    padding: 0px 0px 15px 0px;
    display: flex;
    justify-content: center;
}

.btn-column{
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 10px;
    align-items: center;
}

.btn-column button{
    height: 80px;
    background-color: #000b3d;
    border: none;
    width: 100%;
    border-radius: 8px;
    font-size: 17px;
    padding: 0 45px 0 45px;
}

.div-info-btns{
    flex-grow: 1;
}

.div-info-btns img{
    color: #224ba0;
    padding: 0;
}

.info-logo-btns, .info-text-btns{
    padding: 0;
}

.info-logo-btns{
    width: 50px;
    margin: 0;
    margin-right: 15px;
}

.QR-column{
    width: 270px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.QR-div{
    flex-grow: 2;
}

.QR-div img{
    width: 150px;
    height: 150px;
    cursor: pointer;
    /*border: 2px solid black*/
}

/** footer landing**/

.open {
    background-color: #333;
    color: #eee;
    border-top: 100px solid #333;
    border-right: 100vw solid transparent;
    text-align: center;
  }
  
  .open::after {
    display: block;
    content: "";
    border-top: 100px solid #333;
    border-left: 100vw solid transparent;
    background-color: #fff;
  }
.p60 {
    padding: 60px;
}
.fontFooter_blockchain{
    font-size: 14px !important;
}

.numberBlock {
    border: 1px solid #000b3d;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.textNumber {
    color: #000b3d;
    margin-bottom: 0px;
    font-weight: 700;
    font-size: 20px !important;
}
.infoBlock {
    background: #000b3d;
    color: white;
    text-align: center;
    padding: 20px;
}
.infoTextBlock {
    font-size: 1.5rem !important;
}
.button-chevron:focus {
    border: 0px;
    background: transparent;
}
.button-chevron:selected {
    border: 0px;
    background: transparent;
}

.headerQr {
    position: relative;
    z-index: 2;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #000b3d;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: space-between;
    /* padding-top: 19px; */
    padding-top: 1.22222rem;
}
.logo {
    margin-left: 40px;
}
.colorEsade {
    color: black;
}
.header_bar_clip {
    background-color: #000b3d;
    -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 0);
    clip-path: polygon(0% 0%, 0% 100%, 100% 0);
    height: 29px;
    height: 1.83333rem;
    margin-top: -1px;
    position: absolute;
    top: 100%;
    width: 100%;
}

.card_esade {
    border-radius: 4px;
    border: 1px solid rgba(0, 11, 61, 0.5);
    padding: 20px;
}
.colorTitle {
    color: #000b3d;
}

.nameTitle {
    font-size: 1.3rem;
    margin-bottom: 0px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
}
.flexi {
    display: flex;
    justify-content: center;
}
.dividerQR {
    background-color: #000b3d;
    width: 35%;
    height: 2px !important;
    opacity: 1;
}
.container-Divider {
    padding-left: 30px;
    padding-right: 30px;
}
.dividerBox {
    height: 2px !important;
    opacity: 1;
    background: white;
}
.fontW700 {
    font-weight: 600;
}
.container-block {
    border: 1px solid #000b3d;
    border-radius: 0px 40px 0px 40px;
    position: relative;
}
.container-father-certi {
    padding: 20px;
    background: #f9fafb;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.flexImg {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
}
.container-father-info {
    border: 1px solid #000b3d;
    background: gainsboro;
    border: 1px solid #000b3d;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container-info {
    border: 1px solid #000b3d;
    height: auto;
    border-radius: 20px;
    padding: 30px;
    background: #000b3d;
    color: white;
}

.subtitle-card {
    color: #000b3d;
    font-weight: 600;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 270px;
}

.mb {
    margin-bottom: 0px;
}

.linkColor {
    cursor: pointer;
}
.congratsText {
    text-align: center;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
}
.border-number {
    border: 5px solid #000b3d;
    border-radius: 50%;
    width: 40px;
    /* padding: 10px; */
    align-items: center;
    color: white;
    font-weight: 700;
    height: 40px;
    background: #000b3d;
}
.border-number-second {
    border: 5px solid #000b3d;
    border-radius: 50%;
    width: 50px;
    /* padding: 10px; */
    align-items: center;
    color: white;
    font-weight: 700;
    height: 50px;
    background: #000b3d;
}
.button-chevron {
    border: 0px;
    background: transparent;
}
.chevron-icon {
    color: black;
    font-size: 5rem;
}
.button-chevron:hover {
    border: 0px;
    background: transparent;
}
.chevron-icon:hover {
    color: black;
    font-size: 5rem;
}
.flexi {
    display: flex;
    justify-content: center;
    align-items: center;
}
.diviInfo {
    margin-top: 24px;
    background: #000b3d;
    height: 2px !important;
    opacity: 1 !important;
}
.textChain {
    position: absolute;
    top: -10%;
    padding-right: 20px;
    background: white;
    padding-left: 20px;
    left: 30px;
}
.font1rem {
    font-size: 1.25rem;
}

.footer-good {
    background: #000b3d;
  color: white;
  clip-path: polygon(0 40%, 100% 0, 100% 100%, 0 100%);
  padding: 2rem 20%;
}

.footer-copy {
    background-color: #224ba0;
    color: white;
}

.containerNumber {
    display: flex;
    align-items: center;
    padding-left: 30px;
}
.pcontainer {
    border: 1px solid #000b3d;
    padding: 0px;
}
.containerInfo {
    padding-left: 60px;
    padding-right: 60px;
}

.marginResponsive {
    margin-left: 70px;
}

.spinner1 {
    width: 15px;
    height: 15px;
    color: #eabe3f;
}
.spinner2 {
    width: 20px;
    height: 20px;
    color: #224ba0;
}
.spinner3 {
    width: 25px;
    height: 25px;
    color: #000b3d;
}
@media (max-width: 400px) {
    .textChain {
        position: absolute;
        font-size: 12px;
        top: -4%;
        padding-right: 20px;
        background: white;
        padding-left: 20px;
        left: 19px;
    }
    .containerNumber {
        display: flex;
        align-items: center;
        padding-left: 0px;
    }
    .border-number {
        border: 13px solid #000b3d;
        border-radius: 50%;
        width: 34px;
        /* padding: 10px; */
        align-items: center;
        color: white;
        font-weight: 700;
        height: 40px;
        background: #000b3d;
    }
    .pcontainer {
        padding: 0px;
    }
    .truncate {
        word-wrap: break-word;
        width: 130px;
    }
    .containerInfo {
        padding: 0px;
    }
    .chevron-icon {
        color: black;
        font-size: 2rem;
    }
    .mtResponsive {
        margin-top: 20px;
    }
    .marginResponsive {
        margin-left: 0px;
    }
    .react-pdf__Page__canvas {
        display: block;
        user-select: none;
        width: 325px !important;
        height: 225px !important;
    }
    .container-father-certi {
        /*padding: 20px;*/
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin-bottom: 30px;
    }
    .container-father-info {
        display: flex;
        padding: 20px;
        justify-content: center;
        align-items: center;
    }
}
